import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { find, findIndex, isArray } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';
import { DISPLAY_ISSUE_TYPES_KEYS } from 'common/issueTypesAndStates';

import Spinner from 'components/common/Spinner';
import ReportStore from 'udb/inventory/reports/reducer/report-store/ReportStore';
import { singleRequestHandler } from 'common/requestHelpers';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import WarehouseStore from '../../../store/WarehouseStore';
import { useRequestController } from '../../../hooks';

import ModalBase from '../ModalBase';
import {
  IFacilityModalsState,
  ILocationData,
} from '../../../store/Modals/facilityModals/IFacilityModalsStore';
import { IIssueST, ISlotStatusST } from '../../../interfaces/slotsAndIssuesInterfaces';
import { LocationModalHelp } from './LocationModalHelp';
import { FeedbackReasonType } from './ShareFeedback/feedbackReason.model';
import { LocationModalHeader } from './header/LocationModalHeader';
import { ImageGrid } from './image-grid/ImageGrid';
import { FeedbackDrawer } from './FeedbackDrawer/FeedbackDrawer';
import { FeedbackDrawerToggler } from './FeedbackDrawer/FeedbackDrawerToggler';
import { LocationModalSubHeader } from './header/LocationModalSubHeader';
import { BarcodesInformation } from './barcodes/BarcodesInformation';
import { LocationHistory } from './location-history/LocationHistory';

interface ILocationModalProps {
  opened: boolean;
  closeModal: () => void;
  refreshDataOnClose: IFacilityModalsState['refreshDataOnClose'];
  data: ILocationData | any[]; // This needs to be fixed
  parentPage: IFacilityModalsState['parentPage'];
  reportId: string | null | undefined;
}

export const initialLocationData: ILocationData = {
  userOverride: '',
  verityState: '-',
  location: '',
  version: '-',
  verityValue: '',
  wmsState: '-',
  wmsValue: '',
  slotStatusVersion: '',
  isReview: false,
  userOverrideValue: '',
  length: 0,
  issueType: DISPLAY_ISSUE_TYPES_KEYS.NONE_V_DATA_OUTDATED,
};

export const LocationModal = (props: ILocationModalProps) => {
  const { opened, closeModal, refreshDataOnClose, data, parentPage, reportId } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [locationsData] = useState(data);
  const [locationData, setLocationData] = useState<ILocationData>(initialLocationData);
  const [currentLocationData, setCurrentLocationData] = useState(
    isArray(data) ? find(data, (issue) => issue.selected) : data,
  );
  const [currentLocationIndex, setCurrentLocationIndex] = useState(
    isArray(data) ? findIndex(data, (issue) => issue.selected) : 0,
  );

  const [feedbackDrawerOpen, setFeedbackDrawerOpen] = useState(false);

  const [didUserOverwrite, setDidUserOverwrite] = useState(false);

  const { currentSystemId: systemId } = useFacilityLevelStore().stateFacilityLevel;

  const { requestController } = useRequestController(`${parentPage} - LocationModal`);

  // FIX-ME::TR::2021-08-11:: Re-instate when re-instating the download images function
  // const { name: facilityName } = state.facilityData;

  // update the modal's internal data structures
  // - the list of locations to navigate through
  // - the location currently being displayed
  const updateCurrentLocationAndLocationSet = useCallback(
    (loc: ISlotStatusST, issues: IIssueST[]) => {
      const rowDataAux = getRowForFullReportTable(loc.slot_label, loc, issues);
      const locData = rowDataAux.actions.data;

      setCurrentLocationData(locData);
    },
    [],
  );

  const refreshLocationData = useCallback(
    (locationLabel: string) => {
      switch (parentPage) {
        case 'WarehouseStatus':
          requestController.doRequest({
            request: WarehouseStore.getLocationsData,
            requestParams: [systemId, locationLabel, 1],
            callbackBeforeSend: () => setSpinnerLoading(true),
            callbackSuccess: (r) =>
              updateCurrentLocationAndLocationSet(
                r.locationsData[locationLabel],
                r.locationsData[locationLabel].issues,
              ),
            messageErrorFallback: 'Location Data could not be fetched.',
            callbackFinally: () => setSpinnerLoading(false),
          });
          break;

        case 'Report':
          requestController.doRequest({
            request: ReportStore.getReportData,
            requestParams: [
              systemId,
              reportId,
              locationLabel,
              locationLabel,
              requestController.signal,
            ],
            callbackBeforeSend: () => setSpinnerLoading(true),
            callbackSuccess: (r) =>
              updateCurrentLocationAndLocationSet(
                r.reportData.locations_data[locationLabel],
                r.reportData.issues[locationLabel],
              ),
            messageErrorFallback: 'Report Data could not be fetched.',
            callbackFinally: () => setSpinnerLoading(false),
          });
          break;

        default:
          break;
      }
    },
    [parentPage, reportId, requestController, systemId, updateCurrentLocationAndLocationSet],
  );

  const handleCloseModal = () => {
    if (didUserOverwrite) {
      refreshDataOnClose.refreshData();
    }
    searchParams.delete('location');
    setSearchParams(searchParams);
    closeModal();
  };

  const refreshData = () => {
    setDidUserOverwrite(true);
    refreshLocationData(locationData?.location);
  };

  const sendLocationInfo = (feedbackCategories: FeedbackReasonType[], message: string) => {
    const slotStatusVersion =
      currentLocationData.slotStatusVersion === '-' ? null : currentLocationData.slotStatusVersion;
    // Get the current URL and pass it as link to get back to this modal.
    // NOTE: as the location feedback can be done only form here, this is sufficient.
    const linkToTheCurrentLocation = window.location.href;

    return singleRequestHandler({
      request: WarehouseStore.sendLocationInfoEmail,
      requestParams: [
        systemId,
        currentLocationData.location,
        slotStatusVersion,
        currentLocationData?.wmsSlotStatusVersion || null,
        parentPage,
        reportId || null,
        feedbackCategories,
        message,
        linkToTheCurrentLocation,
      ],
      dispatcher: enqueueSnackbar,
      callbackBeforeSend: () => setSpinnerLoading(true),
      callbackFinally: () => setSpinnerLoading(false),
      messageSuccess: `Feedback on location ${currentLocationData.location} was submitted to Verity.`,
    });
  };

  // Question: why is this needed? locationData == currentLocationData?
  // update current location data
  useEffect(() => {
    // Update location data
    setLocationData(() => ({
      location: currentLocationData.location,
      issueState: currentLocationData.state,
      issueType: currentLocationData.issueType,

      wmsValue: currentLocationData.wmsValue,
      wmsState: currentLocationData.wmsState,
      wmsDate: currentLocationData.wmsDate,
      wmsArticleNo: currentLocationData.wmsArticleNo,
      wmsDescription: currentLocationData.wmsDescription,
      wmsQty: currentLocationData.wmsQty,
      wmsSlotStatusVersion: currentLocationData.wmsSlotStatusVersion,

      verityValue: currentLocationData.verityValue,
      verityState: currentLocationData.verityState,
      verityDate: currentLocationData.verityDate,
      verityAmended: currentLocationData.isReview ? currentLocationData.verityAmended : '',
      originalVerityState: currentLocationData.originalVerityState,
      originalVerityValue: currentLocationData.originalVerityValue,

      userOverride: currentLocationData.userOverride,
      userOverrideValue: currentLocationData.userOverrideValue,
      userOverrideUserName: currentLocationData.userOverrideUserName,
      showContentSetByUser: currentLocationData.userOverride,

      // Use version if only if it exists.
      // A version does not exist when there is no verity_slot_status for a given location
      version:
        currentLocationData.slotStatusVersion !== '-'
          ? currentLocationData.slotStatusVersion
          : null,

      length: currentLocationData.length,
      // Data to be displayed on the table
      rowData: currentLocationData.rowData,

      isReview: currentLocationData.isReview,
      isSnoozed: currentLocationData.isSnoozed,
      slotStatusVersion: currentLocationData.slotStatusVersion,
    }));
  }, [currentLocationData]);

  return (
    <ModalBase
      help={LocationModalHelp}
      testId="c-location-modal"
      opened={opened}
      maxWidth={false}
      disableBackdropClick={true}
      fullScreen
      title={
        <LocationModalHeader
          parentPage={parentPage || 'WarehouseStatus'}
          locationData={locationData}
          allLocations={locationsData as ILocationData[]}
          currentLocationData={currentLocationData}
          setCurrentLocationData={setCurrentLocationData}
          refreshLocationData={refreshLocationData}
          currentLocationIndex={currentLocationIndex}
          setCurrentLocationIndex={setCurrentLocationIndex}
        />
      }
      handleClose={handleCloseModal}
    >
      {spinnerLoading && <Spinner />}

      {locationData?.rowData && <LocationModalSubHeader rowData={locationData?.rowData} />}

      <Grid container>
        <Grid item lg={feedbackDrawerOpen ? 9 : 12}>
          <Grid spacing={1} container>
            <Grid lg={12} md={12} sm={12} item>
              <BarcodesInformation refreshData={refreshData} locationData={locationData} />
            </Grid>
            <Grid lg={12} md={12} sm={12} item>
              <ImageGrid
                systemId={systemId || ''}
                verityState={locationData.verityState}
                locationData={currentLocationData}
                imageUrlsVerityStatus={currentLocationData.imageIds ?? []}
              />
            </Grid>
          </Grid>
          <FeedbackDrawerToggler
            feedbackDrawerOpen={feedbackDrawerOpen}
            setFeedbackDrawerOpen={setFeedbackDrawerOpen}
          />
        </Grid>
        {feedbackDrawerOpen && (
          <Grid item>
            <FeedbackDrawer
              isOpen={feedbackDrawerOpen}
              locationData={locationData}
              setSpinnerLoading={setSpinnerLoading}
              refreshLocationData={refreshLocationData}
              setDidUserOverwrite={setDidUserOverwrite}
              currentLocationIndex={currentLocationIndex}
              sendLocationInfo={sendLocationInfo}
            />
          </Grid>
        )}
      </Grid>
      <Grid spacing={1} container paddingTop={3}>
        <Grid lg={6} md={6} sm={12} item>
          {locationData.location && systemId && (
            <LocationHistory slotLabel={locationData.location} systemId={systemId} />
          )}
        </Grid>
      </Grid>
    </ModalBase>
  );
};
