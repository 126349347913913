import { getLocationInformationService } from 'services/services';

export const getLocationHistory = (
  systemId: string,
  slotLabel: string,
  next_timepoint: string | null,
) => {
  if (next_timepoint) {
    return getLocationInformationService().getLocationHistory(
      systemId,
      slotLabel,
      undefined,
      next_timepoint,
    );
  }
  return getLocationInformationService().getLocationHistory(systemId, slotLabel);
};
