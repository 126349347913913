import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
} from '@mui/lab';
import { Theme, Tooltip, Typography } from '@mui/material';
import capitalize from 'lodash/capitalize';
import { makeStyles } from 'tss-react/mui';
import Badge from 'components/common/Badge';

import { Box } from 'components/common/Box';
import { DATETIME_FORMAT_WITH_WEEKDAY } from 'common/datetimeFormats';
import { formatDateInTimezone } from 'common/functions/dateTimeFunctions';
import { formatTimeLocationHistory } from './utils/formatTimeLocationHistory';
import { LocationHistoryItem } from './utils/transformLocationHistory';
import { getTimeLineIcon } from './utils/getTimeLineIcon';
import { timelineMapState } from './utils/timelineMapState';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    // overflow: 'show',
  },

  itemTitle: {
    color: 'var(--Text-Primary, rgba(0, 0, 0, 0.87))',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '0.15px',
  },

  itemTitleTime: {
    color: 'var(--Text-Primary, rgba(0, 0, 0, 0.87))',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  timelineSeperator: {
    width: '1px',
  },
  timeLineDot: {
    borderColor: '#bdbdbd',
    padding: '7px',
    borderWidth: '1px',
    margin: '0 auto',
  },

  badgeHistoryContent: {
    fontSize: 14,
    fontWeight: '400',
    wordWrap: 'break-word',
    padding: '8px 12px',
  },
  boxOfBadges: {
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    width: '100%',
    paddingTop: '4px',
  },
}));

const timeLineItemTitle: { [key: string]: string } = {
  WMS_UPDATE: 'WMS Update',
  DRONE_REPORT: 'Drone Report',
  EXCLUSION_STATUS_UPDATE: 'Exclusion Status Update',
};

export const CustomTimeLineItem = (props: { item: LocationHistoryItem; lastItem?: boolean }) => {
  const { item, lastItem } = props;
  const { classes } = useStyles();

  return (
    <TimelineItem className={classes.root}>
      <TimelineSeparator>
        <Tooltip title={timeLineItemTitle[item.type]}>
          <TimelineDot className={classes.timeLineDot} variant="outlined" color="secondary">
            {getTimeLineIcon(item, !!lastItem)}
          </TimelineDot>
        </Tooltip>
        <TimelineConnector
          className={classes.timelineSeperator}
          sx={{ bgcolor: lastItem ? 'transparent' : '#bdbdbd' }}
        />
      </TimelineSeparator>
      <TimelineContent sx={{ m: '40 40' }}>
        <Typography className={classes.itemTitle} component="span">
          {timeLineItemTitle[item.type]}
        </Typography>
        <Tooltip title={formatDateInTimezone(item.timestamp, DATETIME_FORMAT_WITH_WEEKDAY) || ''}>
          <Typography className={classes.itemTitleTime} component="span" sx={{ cursor: 'default' }}>
            {` • ${formatTimeLocationHistory(item.timestamp)}`}
          </Typography>
        </Tooltip>

        <Box className={classes.boxOfBadges}>
          {item.state === 'BARCODE' &&
            item.barcodes.map((barcode) => (
              <Badge
                key={barcode}
                className={classes.badgeHistoryContent}
                bgcolor="#F5F5F5"
                color="#000000"
                bigBadge={false}
              >
                <span>{barcode}</span>
              </Badge>
            ))}
          {item.state !== null && item.state !== 'BARCODE' && (
            <Badge
              className={classes.badgeHistoryContent}
              bgcolor="#F5F5F5"
              color="#000000"
              bigBadge={false}
            >
              <>{timelineMapState(item.state)}</>
            </Badge>
          )}
          {item.exclusion_status !== null && (
            <Badge
              className={classes.badgeHistoryContent}
              bgcolor="#F5F5F5"
              color="#000000"
              bigBadge={false}
            >
              <>{capitalize(item.exclusion_status)}</>
            </Badge>
          )}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};
