import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { makeStyles } from 'tss-react/mui';
import { Theme, Button } from '@mui/material';
import BaseCard from 'components/BaseCard/BaseCard';
import { Box } from 'components/common/Box';
import { getLocationHistory } from './api/getLocationHistory';
import { CustomTimeLineItem } from './CustomTimeLineItem';
import {
  transformLocationHistory,
  TransformedHistoryData,
  LocationHistoryItem,
} from './utils/transformLocationHistory';

const useStyles = makeStyles()((theme: Theme) => ({
  timelineDiv: {
    height: '400px',
    overflowY: 'auto',
    padding: 0,
    paddingTop: 10,
    '&::-webkit-scrollbar': {
      height: 9,
      width: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#BDBDBD',
      borderRadius: 200,
    },
  },

  loadMoreDiv: {
    paddingLeft: '70px',
    paddingTop: '5px',
  },

  timeline: {
    [`& .${timelineItemClasses.root}:before`]: {
      flex: '0 1 auto',
      padding: 0,
    },
  },
}));

export const LocationHistory = (props: { slotLabel: string; systemId: string }) => {
  const { systemId, slotLabel } = props;
  const { classes } = useStyles();

  const [nextTimepoint, setNextTimepoint] = useState<string | null>(null);
  const [historyData, setHistoryData] = useState<LocationHistoryItem[]>([]);

  const fetchMoreHistory = async (): Promise<TransformedHistoryData> => {
    if (!nextTimepoint && data) {
      return Promise.resolve({ next_timepoint: null, items: [] });
    }
    const response = await getLocationHistory(systemId, slotLabel, nextTimepoint);
    const transformedData = transformLocationHistory(response.data);
    return transformedData;
  };

  const { isLoading, data } = useQuery<TransformedHistoryData, Error>({
    queryKey: ['locationHistory', systemId, slotLabel, nextTimepoint],
    queryFn: fetchMoreHistory,
  });

  const resetHistoryState = () => {
    setNextTimepoint(null);
    setHistoryData([]);
  };

  useEffect(() => {
    if (data) {
      setHistoryData((historyData) => [...historyData, ...data.items]);
    }
  }, [data]);

  const handleClick = () => {
    if (data) {
      setNextTimepoint(data.next_timepoint);
    }
  };

  useEffect(() => {
    resetHistoryState();
  }, [slotLabel]);

  return (
    <BaseCard
      cardFor="location_history"
      showHeader={true}
      showContent={true}
      showActionButtons={false}
      isLoading={isLoading}
      title="History"
      subtitle="The timeline of the location based on WMS Updates and Drone Reports."
      scrollable
    >
      <Box className={classes.timelineDiv}>
        <Timeline className={classes.timeline}>
          {historyData.map((item, i) => (
            <CustomTimeLineItem
              key={item.timestamp}
              item={item}
              lastItem={i === historyData.length - 1 && data?.next_timepoint === null}
            />
          ))}
        </Timeline>
        {data && data.next_timepoint && (
          <div className={classes.loadMoreDiv}>
            <Button color="primary" variant="outlined" size="small" onClick={handleClick}>
              Load More
            </Button>
          </div>
        )}
      </Box>
    </BaseCard>
  );
};
