import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { makeStyles } from 'tss-react/mui';

import { DataGridPremium } from '@mui/x-data-grid-premium';
import { locationColumns } from './models/locationColumns.model';
import { transformLocationRows } from './utils/transformLocationRows.util';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: 500,
    border: 'none',
    borderRadius: theme.spacing(1),
    width: '100%',

    '& .MuiDataGrid-virtualScroller': {
      overflowY: 'scroll !important' as 'scroll',
      maxHeight: 400,
    },

    '& .MuiDataGrid-container--top [role=row]': {
      background: 'none',
      width: '100%',
    },

    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme.palette.shades.white,
      marginBottom: theme.spacing(1),
      borderRadius: theme.spacing(1),
      border: '1px #EEEEEE solid',
    },

    '& .MuiDataGrid-row': {
      borderRadius: theme.spacing(1),
      border: '1px #EEEEEE solid',
      backgroundColor: theme.palette.shades.white,
      ':hover': {
        backgroundColor: theme.palette.shades.white,
      },
    },
    '& .MuiDataGrid-cell': {
      borderTopStyle: 'none',
      background: 'none',
    },
    '.MuiDataGrid-cell:focus-within,': {
      outline: 'none !important',
    },
    '.MuiDataGrid-columnHeader:focus-within,': {
      outline: 'none !important',
    },

    '& ::-webkit-scrollbar': {
      display: 'none',
    },
  },

  chip: {
    width: '100%',
  },
}));

export const BarcodesInformation = ({
  refreshData,
  locationData,
}: {
  refreshData: () => void;
  locationData: ILocationData;
}) => {
  const { classes } = useStyles();

  return (
    <DataGridPremium
      className={classes.root}
      rows={locationData?.rowData ? transformLocationRows(locationData.rowData) : []}
      columns={locationColumns}
      hideFooter
      rowGroupingColumnMode="single"
      disableRowSelectionOnClick
      autoHeight
      disableAggregation
    />
  );
};
