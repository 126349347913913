// variables, functions, configurations
import { ISSUE_STATES, ISSUE_TYPES } from './issueTypesAndStates';

export const ISSUE_TAB_ALLOWED_DATA = {
  STATES: [ISSUE_STATES.NEW, ISSUE_STATES.KNOWN],
  ISSUE_TYPES: [
    ISSUE_TYPES.WMS_BARCODE_VERITY_EMPTY,
    ISSUE_TYPES.WMS_BARCODE_NOT_EQUAL_VERITY_BARCODE,
    ISSUE_TYPES.WMS_EMPTY_VERITY_BARCODE,
    ISSUE_TYPES.WMS_EMPTY_VERITY_NOTEMPTY,
  ],
};

export const SOLVED_TAB_ALLOWED_DATA = {
  STATES: [ISSUE_STATES.SOLVED, ISSUE_STATES.NA],
};

export const SNOOZED_TAB_ALLOWED_DATA = {
  STATES: [ISSUE_STATES.SNOOZED],
};

export const POTENTIAL_TAB_ALLOWED_DATA = {
  ISSUE_TYPES: [ISSUE_TYPES.INCONCLUSIVE_WMS_BARCODE_VERITY_NOTEMPTY, ISSUE_TYPES.C001C_MISSING_BC],
};

export const INVALID_TAB_ALLOWED_DATA = {
  ISSUE_TYPES: [ISSUE_TYPES.INCONCLUSIVE_WMS_INVALID],
};

export const INCONCLUSIVE_TAB_ALLOWED_DATA = {
  ISSUE_TYPES: [
    ISSUE_TYPES.INCONCLUSIVE_WMS_BARCODE_VERITY_NORESULT,
    ISSUE_TYPES.INCONCLUSIVE_WMS_EMPTY_VERITY_NORESULT,
  ],
};
