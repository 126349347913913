import { GridColDef } from '@mui/x-data-grid-premium';
import { LocationRow } from './LocationRow.model';

export const locationColumns: GridColDef<LocationRow>[] = [
  { field: 'expectedContent', headerName: 'Expected content', flex: 2 },
  { field: 'contentFound', headerName: 'Content found', flex: 2 },
  { field: 'foundAt', headerName: 'Found at', flex: 1 },
  { field: 'shouldBeAt', headerName: 'Should be at', flex: 1 },
  { field: 'wmsQuantity', headerName: 'WMS quantity', flex: 1 },
  { field: 'articleNumber', headerName: 'Article number', flex: 1 },
  { field: 'customer', headerName: 'Customer', flex: 1 },
];
