import { barcodeStatusEnum } from '../models/LocationRow.model';

export const matchBarcode = (expectedBarcode: string, foundBarcode: string) => {
  switch (true) {
    case foundBarcode !== 'No match' && expectedBarcode !== 'No match':
      return barcodeStatusEnum.Match;
    case foundBarcode !== 'No match' && expectedBarcode === 'No match':
      return barcodeStatusEnum.Unexpected;
    default:
      return barcodeStatusEnum.Missing;
  }
};
