export enum barcodeStatusEnum {
  Match = 'Match',
  Unexpected = 'Unexpected',
  Missing = 'Missing',
}

export type LocationRow = {
  id: number;
  barcodeStatus: 'Match' | 'Unexpected' | 'Missing';
  expectedContent: string;
  contentFound: string;
  foundAt: string;
  shouldBeAt: string;
  wmsQuantity: string;
  articleNumber: string;
  customer: string;
};
