import { Paper, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import Spinner from 'components/common/Spinner';
import { Box } from 'components/common/Box';
import IssuesServices from 'services/IssuesServices';
import { VERITY_STATES } from 'common/slotStates';
import { getImage } from 'common/functions/imageFunctions';
import { MutationNames } from 'ts-types/MutationNames';
import { useEffect } from 'react';
import { IImageGetResponseST } from 'codegen/image/api';
import { QueryNames } from 'ts-types/QueryNames';
import { webSocket } from 'shared/web-socket/web-socket';
import { locationStyles } from '../styles';
import { ImageGridComponent } from './ImageGridComponent';
import { PictureCounter } from './PictureCounter';
import { getImageText } from './utils/getImageText';
import { subscribeToImageStatus } from './web-socket/subscribe-to-image-status';
import { ImageSubscribersOptions, imageSubscribers } from './web-socket/subscribers';

export const ImageGrid = (props: {
  verityState: '-' | VERITY_STATES;
  locationData: ILocationData;
  systemId: string;
  imageUrlsVerityStatus: string[];
}) => {
  const { systemId, verityState, locationData, imageUrlsVerityStatus } = props;
  const { location, slotStatusVersion, isReview } = locationData;

  const { classes } = locationStyles();

  const socket = webSocket();

  const {
    isLoading: isLoadingImages,
    data = { urls: [], num_requested: 0, num_failed: 0 } as IImageGetResponseST,
    refetch,
  } = useQuery({
    queryKey: [QueryNames.LOCATION_MODAL_GET_IMAGES, location],
    queryFn: () => IssuesServices.getImages(systemId, location, slotStatusVersion, isReview),
    select: ({ data }) => data,
    enabled: slotStatusVersion !== '-',
  });

  useEffect(() => {
    if (locationData) {
      refetch();
    }
  }, [locationData, refetch]);

  useEffect(() => {
    subscribeToImageStatus({
      socket,
      onSubscribe: ({ image_id }) => {
        if (imageUrlsVerityStatus.includes(image_id)) {
          refetch();
        }
      },
    });

    return () => socket.unsubscribeAll<ImageSubscribersOptions>(imageSubscribers);
  }, [imageUrlsVerityStatus, refetch, socket]);

  const { mutate: generateAndDownloadZip, isPending: imagesDownloading } = useMutation({
    mutationKey: [MutationNames.DOWNLOAD_IMAGES, location],
    mutationFn: async () => {
      const zip = new JSZip();

      const getImagePromises = data.urls.map((url: string, index: number) => {
        const imageName = `${location}-img-${index + 1}.png`;
        return getImage(url).then((imageData: any) => zip.file(imageName, imageData));
      });

      await Promise.all(getImagePromises);
      const content = await zip.generateAsync({ type: 'blob' });
      const zipFolderName = `${location}-images.zip`;
      saveAs(content, zipFolderName);
    },
  });

  return data.urls.length ? (
    <div>
      {!isLoadingImages ? (
        <>
          <PictureCounter
            imageUrls={data.urls}
            imagesDownloading={imagesDownloading}
            handleDownloadImages={generateAndDownloadZip}
          />
          <ImageGridComponent
            imageUrls={data.urls}
            resultId={locationData.rowData.slotStatus.verity_status.result_id}
            systemId={systemId}
          />
        </>
      ) : (
        <Box className={classes.spinnerLoadingImages}>
          <Spinner />
        </Box>
      )}
    </div>
  ) : (
    <Paper className={classes.paper} elevation={0}>
      <div className={classes.imageContainer}>
        <Typography
          className={classes.imageContainerText}
          color="textSecondary"
          align="center"
          variant="h6"
        >
          {getImageText(verityState, data.urls.length, data.num_requested)}
        </Typography>
      </div>
    </Paper>
  );
};
