import { createContext, useContext, useMemo } from 'react';
import * as settings from 'common/settings';
import * as tables from 'common/Tables';
import { FLEET_OVERVIEW_COLUMNS } from '../../pages/GroundControl/pages/FleetOverview/FleetOverviewTableDefinition';

// context and store
import { useFacilityLevelStore } from '../FacilityLevelStore/facilityLevelStore';

// interfaces and types
import { ISettingsData } from './settingsInterfaces';

// Create settings Context
const Context = createContext<ISettingsData | object>({});

export const SettingsContextProvider = ({ children }: { children: any }) => {
  // store context
  const { stateFacilityLevel } = useFacilityLevelStore();

  // Initialize settings data returned from functions
  // defined in tables and settings files
  const data = useMemo(
    () => ({
      REPORTS_TABLE_TABS: settings.TABS(stateFacilityLevel.facilitySettings).REPORTS_TABS,
      REPORTS_FOR_REVIEW_TABLE_TABS: settings.TABS(stateFacilityLevel.facilitySettings)
        .REPORT_REVIEW_TABS,
      REPORT_TABLE_TABS: settings
        .TABS(stateFacilityLevel.facilitySettings)
        .REPORT_TABS.filter((tab) => tab.show),
      WAREHOUSE_TABLE_TABS: settings
        .TABS(stateFacilityLevel.facilitySettings)
        .WAREHOUSE_STATUS_TABS.filter((tab) => tab.show),
      WAREHOUSE_UPLOADS_TABLE_TABS: settings.TABS(stateFacilityLevel.facilitySettings)
        .WAREHOUSE_UPLOADS_TABS,

      // TABLE COLUMNS FOR WAREHOUSE STATUS PAGE
      WHS_ISSUES_COLUMNS: tables.WHS_ISSUES_COLUMNS(stateFacilityLevel.facilitySettings),
      WHS_SOLVED_COLUMNS: tables.WHS_SOLVED_COLUMNS(stateFacilityLevel.facilitySettings),
      WHS_AMENDED_COLUMNS: tables.WHS_AMENDED_COLUMNS(stateFacilityLevel.facilitySettings),
      WHS_FULL_REPORT_COLUMNS: tables.WHS_FULL_REPORT_COLUMNS(stateFacilityLevel.facilitySettings),
      WHS_INVALID_COLUMNS: tables.WHS_INVALID_COLUMNS(stateFacilityLevel.facilitySettings),
      WHS_POTENTIAL_AND_INCONCLUSIVE_COLUMNS: tables.WHS_POTENTIAL_AND_INCONCLUSIVE_COLUMNS(
        stateFacilityLevel.facilitySettings,
      ),

      // TABLE COLUMNS FOR REPORT PAGE
      REPORT_ISSUES_COLUMNS: tables.REPORT_ISSUES_COLUMNS(stateFacilityLevel.facilitySettings),
      REPORT_SOLVED_COLUMNS: tables.REPORT_SOLVED_COLUMNS(stateFacilityLevel.facilitySettings),
      REPORT_AMENDED_COLUMNS: tables.REPORT_AMENDED_COLUMNS(stateFacilityLevel.facilitySettings),
      REPORT_POTENTIAL_AND_INCONCLUSIVE_COLUMNS: tables.REPORT_POTENTIAL_AND_INCONCLUSIVE_COLUMNS(
        stateFacilityLevel.facilitySettings,
      ),
      REPORT_INVALID_COLUMNS: tables.REPORT_INVALID_COLUMNS(stateFacilityLevel.facilitySettings),
      REPORT_FULL_REPORT_COLUMNS: tables.REPORT_FULL_REPORT_COLUMNS(
        stateFacilityLevel.facilitySettings,
      ),
      REPORT_REVIEW_COLUMNS: tables.REPORT_REVIEW_COLUMNS(stateFacilityLevel.facilitySettings),

      // TABLE COLUMNS FOR WHS UPLOADS PAGE
      WAREHOUSE_DATA_UPLOADS_COLUMNS: tables.WAREHOUSE_DATA_UPLOADS_COLUMNS(
        stateFacilityLevel.facilitySettings,
      ),

      // TABLE COLUMNS FOR REPORTS PAGE
      REPORTS_ALL_COLUMNS: tables.REPORTS_ALL_COLUMNS(stateFacilityLevel.facilitySettings),
      REPORTS_FINISHED_COLUMNS: tables.REPORTS_FINISHED_COLUMNS(
        stateFacilityLevel.facilitySettings,
      ),
      REPORTS_ONGOING_COLUMNS: tables.REPORTS_ONGOING_COLUMNS(stateFacilityLevel.facilitySettings),
      REPORTS_SCHEDULED_COLUMNS: tables.REPORTS_SCHEDULED_COLUMNS(
        stateFacilityLevel.facilitySettings,
      ),
      REPORTS_TO_REVIEW_COLUMNS: tables.REPORTS_TO_REVIEW_COLUMNS(
        stateFacilityLevel.facilitySettings,
      ),
      REPORTS_ARCHIVED_COLUMNS: tables.REPORTS_FINISHED_COLUMNS(
        stateFacilityLevel.facilitySettings,
      ),
      /**
       * TABLE COLUMNS FOR FLEET OVERVIEW
       */
      FLEET_OVERVIEW_COLUMNS: FLEET_OVERVIEW_COLUMNS(stateFacilityLevel.facilitySettings),

      // FACILITY SETTINGS
      FACILITY_SETTINGS_BASE64_FILE_EXTENSION: 'vrt',
      FACILITY_SETTINGS_CARDS_VISIBILITY: settings.FACILITY_SETTINGS_CARDS_VISIBILITY(),

      // UI SETTINGS
      HEADER_SETTINGS: settings.HEADER_SETTINGS(),
      HOME_SCREEN_SETTINGS: settings.HOME_SCREEN_SETTINGS(),
      FACILITY_MENU_SETTINGS: settings.FACILITY_MENU_SETTINGS(),
    }),
    [stateFacilityLevel.facilitySettings],
  );

  return <Context.Provider value={data}>{children}</Context.Provider>;
};

export const useSettingsStore = () => useContext<ISettingsData | any>(Context);
