import { LocationRow, barcodeStatusEnum } from '../models/LocationRow.model';
import { matchBarcode } from './matchBarcode.util';

export const transformLocationRows = ({
  contentExpected,
  matchArray,
  contentFound,
  barcodeExpectedFoundOn,
  barcodeFoundShouldBeOn,
  wmsQty,
  wmsArticleNo,
  wmsCustomer,
}: {
  contentExpected: string[];
  matchArray: string[];
  contentFound: string[];
  barcodeExpectedFoundOn: string;
  barcodeFoundShouldBeOn: string;
  wmsQty: string[];
  wmsArticleNo: string[];
  wmsCustomer: string[];
}): LocationRow[] => {
  const maxLength = Math.max(contentExpected.length, contentFound.length);

  const result = Array.from({ length: maxLength }, (_, i) => {
    const expected = contentExpected[i] || '';
    const found = contentFound[i] || '';
    const barcodeStatus = matchBarcode(expected, found);

    return {
      id: i + 1,
      barcodeStatus,
      expectedContent: expected && expected !== 'No match' ? expected : '',
      contentFound: found && found !== 'No match' ? found : '',
      foundAt:
        barcodeStatus === barcodeStatusEnum.Missing && barcodeExpectedFoundOn !== '-'
          ? barcodeExpectedFoundOn
          : '',
      shouldBeAt:
        barcodeStatus === barcodeStatusEnum.Unexpected && barcodeFoundShouldBeOn !== '-'
          ? barcodeFoundShouldBeOn
          : '',
      wmsQuantity: wmsQty[i] && wmsQty[i] !== '-' ? wmsQty[i] : '',
      articleNumber: wmsArticleNo[i] && wmsArticleNo[i] !== '-' ? wmsArticleNo[i] : '',
      customer: wmsCustomer[i] && wmsCustomer[i] !== '-' ? wmsCustomer[i] : '',
    };
  });

  return result;
};
