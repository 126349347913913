import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { makeStyles } from 'tss-react/mui';
import { ImageList, ImageListItem, Theme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isTextAreaOrInputActive } from 'common/functions/domFunctions';

const NUMBER_OF_IMAGES_TO_DISPLAY = 3;

const useStyles = makeStyles()((theme: Theme) => ({
  imageListContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    margin: 'auto 0',
    position: 'relative',

    width: '100%',
    paddingBottom: 16,

    '&::-webkit-scrollbar': {
      height: 9,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#6359D1',
      borderRadius: 200,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#D9D9D9',
      borderRadius: 10,
    },
  },

  imageListItem: {
    width: `calc((100% / ${NUMBER_OF_IMAGES_TO_DISPLAY}) - ${theme.spacing(1)})`,
    minWidth: `calc((100% / ${NUMBER_OF_IMAGES_TO_DISPLAY}) - ${theme.spacing(1)})`,
    aspectRatio: '4 / 3',
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
  },
}));

export const ImageGridComponent = ({
  imageUrls,
  resultId,
  systemId,
}: {
  imageUrls: string[];
  resultId: string;
  systemId: string;
}) => {
  const { classes } = useStyles();

  const imageListContainer = useRef<HTMLUListElement>(null);
  const imageListItemRefs = useRef<HTMLLIElement[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    const imageToScrollTo = imageListItemRefs.current[selectedImageIndex];
    imageListContainer?.current?.scrollTo({
      left: imageToScrollTo?.offsetLeft,
      behavior: 'smooth',
    });
  }, [selectedImageIndex]);

  const scrollToPrevImage = useCallback(() => {
    const prev = Math.max(selectedImageIndex - 1, 0);
    setSelectedImageIndex(prev);
  }, [selectedImageIndex]);

  const scrollToNextImage = useCallback(() => {
    const next = Math.min(selectedImageIndex + 1, imageUrls.length - NUMBER_OF_IMAGES_TO_DISPLAY);
    setSelectedImageIndex(next);
  }, [selectedImageIndex, imageUrls?.length]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isTextAreaOrInputActive()) return;

      if (event.code === 'ArrowUp') scrollToPrevImage();
      if (event.code === 'ArrowDown') scrollToNextImage();
    },
    [scrollToPrevImage, scrollToNextImage],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    setSelectedImageIndex(0);
  }, [imageUrls]);

  return (
    <ImageList className={classes.imageListContainer} gap={8} ref={imageListContainer}>
      {imageUrls.map((imageURL, index) => (
        <ImageListItem
          className={classes.imageListItem}
          key={imageURL}
          ref={(el) => (imageListItemRefs.current[index] = el as HTMLLIElement)}
        >
          <TransformWrapper>
            <TransformComponent>
              <img
                src={imageURL}
                alt={`DronePic - ${index}`}
                loading="lazy"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </TransformComponent>
          </TransformWrapper>
        </ImageListItem>
      ))}
    </ImageList>
  );
};
